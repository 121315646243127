document.addEventListener("keydown", (event) => {
  if (event.key.toLowerCase() == "h") {
    const hEvent = new Event("claude:hKeyDown");
    hEvent.detail = { srcEvent: event };
    document.dispatchEvent(hEvent);
  }
});

document.addEventListener("keypress", (event) => {
  if (event.key.toLowerCase() == "h") {
    const hEvent = new Event("claude:hKeyPress");
    hEvent.detail = { srcEvent: event };
    document.dispatchEvent(hEvent);
  }
});

document.addEventListener("keyup", (event) => {
  if (event.key.toLowerCase() == "h") {
    const hEvent = new Event("claude:hKeyUp");
    hEvent.detail = { srcEvent: event };
    document.dispatchEvent(hEvent);
  }
});
